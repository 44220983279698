import React from "react"
import Diy from "../components/products/diy"
import Layout from "../components/layout/t1/layout"

const diy = () => {
  return (
    <Layout>
      <Diy />
    </Layout>
  )
}

export default diy
