import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "./index.scss"
import Seo from "../../seo"
import { Helmet } from "react-helmet"
const Diy = ({ _mock }) => {
  let frontmatter = {}

  try {
    const result = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(diyproduct)/" } }) {
          nodes {
            frontmatter {
              topSectionImage
              topSectionSmallTitle
              topSectionTitle
              topSectionDescription
              linkButton1
              titleButton1
              linkButton2
              titleButton2
              TitleSection2
              section3Image
              section3Icon
              section3title1
              section3title2
              section3Description
              section4Image
              section4Icon
              section4title1
              section4title2
              section4Description
              bottomSectionImage
              bottomSectionSmallTitle
              bottomSectionTitle
              bottomSectionDescription
              bottomSectionLinkButton
              bottomSectionTitleButton
            }
          }
        }
      }
    `)

    frontmatter = result.allMdx.nodes[0].frontmatter
  } catch (error) {
    frontmatter = _mock
  }
  return (
    <div id="diy-product" className="diy-product" data-testid="diy-product">
      <Seo
        title="Do it Yourself Maximo"
        description="MAXapps enables Maximo professionals to build performant, secure, flexible, and versatile business processes."
      />
      <Helmet prepend>
        <meta property="og:title" content="MAXapps Maximo: Do it yourself" />
        <meta
          property="og:description"
          content="MAXapps enables Maximo professionals to build performant, secure, flexible, and versatile business processes."
        />
        <meta
          property="og:image"
          content="https://maxapps-maximo.com/og/diy.jpg"
        />
        <meta
          property="og:url"
          content="https://maxapps-maximo.com/do-it-yourself"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@maxapps" />
        <meta name="twitter:title" content="MAXapps Maximo: Do it yourself" />
        <meta
          name="twitter:description"
          content="With MAXapps, building Maximo mobile applications becomes easy. Uniting the IBM Maximo workflow and MAXapps Designer to design, stage, and deploy, you can take..."
        />
        <meta
          name="twitter:image"
          content="https://maxapps-maximo.com/og/diy.jpg"
        />
      </Helmet>
      <main className="main-diy">
        <div className="top-section-diy d-flex justify-content-center">
          <div className="text-center">
            <div className="">
              <div className="diy-top-title">
                {/* <img
                  src={frontmatter?.topSectionImage}
                  aria-hidden="true"
                  alt="maximo ibm: do it yourself"
                  title="maximo ibm: do it yourself"
                />

                <p className="p">{frontmatter?.topSectionSmallTitle}</p> */}
              </div>
              <h1 className="h1 py-4">{frontmatter?.topSectionTitle}</h1>
            </div>
            <div className="description py-3">
              <p
                dangerouslySetInnerHTML={{
                  __html: frontmatter?.topSectionDescription,
                }}
              ></p>
            </div>
            <div className="py-2">
              <Link className="button1" to={frontmatter?.linkButton1}>
                {frontmatter?.titleButton1}
              </Link>
              <Link className="button2" to={frontmatter?.linkButton2}>
                {frontmatter?.titleButton2}
              </Link>
            </div>
          </div>
        </div>
        <section className="section-diy2">
          <div className="text-center">
            <p className="h2">{frontmatter?.TitleSection2}</p>
          </div>
        </section>
        <section className="mid-section">
          <div className="row">
            <div className="order-2 order-lg-1 col-lg-6 p-2 text-center">
              <img
                src={frontmatter?.section3Image}
                alt="maximo ibm: Dramatically improve your users experience"
                title="maximo ibm: Dramatically improve your users experience"
                className="img "
              />
            </div>
            <div className="order-1 order-lg-2  col-lg-6 text-center text-lg-start">
              <div className="">
                {/* <img
                  src={frontmatter?.section3Icon}
                  alt="maximo ibm: Dramatically Reduce Build & Deploy Time"
                  title="maximo ibm: Dramatically Reduce Build & Deploy Time"
                  className="py-1"
                /> */}
                <h2 className="h3 py-1">{frontmatter?.section3title1}</h2>
                <p
                  className="h2 py-2"
                  dangerouslySetInnerHTML={{
                    __html: frontmatter?.section3title2,
                  }}
                ></p>
              </div>
              <div className="p pt-2">
                <p
                  dangerouslySetInnerHTML={{
                    __html: frontmatter?.section3Description,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </section>
        <section className="mid-section">
          <div className="row">
            <div className=" col-lg-6 text-center text-lg-start">
              <div className="">
                {/* <img
                  src={frontmatter?.section4Icon}
                  className="py-1"
                  alt="maximo ibm: Dramatically reduce build & deploy times"
                  title="maximo ibm: Dramatically reduce build & deploy times"
                /> */}
                <h2 className="h3 py-1">{frontmatter?.section4title1}</h2>
                <p
                  className="h2 py-2"
                  dangerouslySetInnerHTML={{
                    __html: frontmatter?.section4title2,
                  }}
                ></p>
              </div>
              <div className="p pt-2">
                <p
                  dangerouslySetInnerHTML={{
                    __html: frontmatter?.section4Description,
                  }}
                ></p>
              </div>
            </div>
            <div className=" col-lg-6 p-2 text-center">
              <img
                src={frontmatter?.section4Image}
                alt="maximo ibm: Dramatically reduce build & deploy times"
                title="maximo ibm: Dramatically reduce build & deploy times"
                className="img "
              />
            </div>
          </div>
        </section>

        <section className="bottom-section-diy">
          <div className="text-center">
            <div className="">
              <div className="">
                <div className="">
                  {/* <img
                    src={frontmatter?.bottomSectionImage}
                    aria-hidden="true"
                    alt="maximo ibm: MAXAPPS DIY"
                    title="maximo ibm: MAXAPPS DIY"
                  /> */}
                </div>
                <p className="p">{frontmatter?.bottomSectionSmallTitle}</p>
                <p className="h2">{frontmatter?.bottomSectionTitle}</p>
              </div>
              <div className="description">
                <p
                  dangerouslySetInnerHTML={{
                    __html: frontmatter?.bottomSectionDescription,
                  }}
                ></p>
              </div>
              <div className="button1">
                <a
                  href={frontmatter?.bottomSectionLinkButton}
                  className="css-1v1c6i3"
                >
                  {frontmatter?.bottomSectionTitleButton}
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default Diy
